<template>
  <div class="p-4 pt-8 container max-w-screen-lg mx-auto">
    <filter-form
      @student-searched="triggerSearchStudent"
      class="mb-8 mx-auto"
    ></filter-form>

    <p class="text-center uppercase tracking-wide" v-if="students.length === 0">
      Belum Ada peserta didik
    </p>
    <div v-if="isProcessing">Memuat</div>

    <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
      <item-card
        class="bg-white rounded mb-4"
        v-for="(item, key) in students"
        :key="key"
        :item="item"
      >
        <!-- Menu -->
        <template #menu>
          <button class="button" @click="triggerShowForm(item, 'core')">
            <span>Informasi Pribadi</span>
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </template>
        <section class="">
          <span class="inline-block bg-white text-xs text-gray-600">
            ANGKATAN {{ item.accepted_at | only_year }}
          </span>
          <span class="block text-lg mt-1">{{ item.name }}</span>
          <span class="block text-gray-600">{{ item.phone }}</span>
        </section>
      </item-card>
    </div>

    <!-- pagination -->
    <pagination-links
      :links="links"
      @clicked="handleLinkClicked"
    ></pagination-links>

    <!-- forms -->
    <section>
      <core-form></core-form>
      <address-form></address-form>
      <parent-form></parent-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      isProcessing: false,
    };
  },
  methods: {
    triggerShowForm(student, formType) {
      this.SET_SELECTED_STUDENT(student);
      this.SHOW_FORM(formType);
      this.hideMenu();
    },

    async triggerSearchStudent({ q, targetYear }) {
      let query = new URLSearchParams({
        q: q,
        target_year: targetYear,
      });
      let url = process.env.VUE_APP_API_URL + `/api/student?${query}`;
      await this.fetchStudents(url);
    },

    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },

    async handleLinkClicked(link) {
      this.isProcessing = true;
      await this.fetchStudents(link);
      this.isProcessing = false;
    },
    ...mapMutations("student", ["SHOW_FORM", "SET_SELECTED_STUDENT"]),
    ...mapActions("student", ["fetchStudents"]),
  },
  computed: {
    ...mapGetters("student", ["students", "links", "form_visibility"]),
  },
  mounted() {
    this.fetchStudents();
  },
  components: {
    PaginationLinks: () => import("@/components/PaginationLinks.vue"),
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
    FilterForm: () => import("@/components/Student/FilterForm.vue"),
    CoreForm: () => import("@/components/Student/InformationForm/CoreForm.vue"),
    AddressForm: () =>
      import("@/components/Student/InformationForm/AddressForm.vue"),
    ParentForm: () =>
      import("@/components/Student/InformationForm/ParentForm.vue"),
  },
  name: "StudentList",
};
</script>